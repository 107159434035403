
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Location } from 'vue-router';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesCouplesPublicExpertIndexGroup,
  V1EntitiesCouplesPublicExpertIndexCouple,
} from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';
import PageTitle from '@/components/common/PageTitle.vue';
import UserForRateCardCircle from '@/components/assessmentChecklist/UserForRateCardCircle.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import DialogAddingUsersForRate from '@/components/assessmentChecklist/DialogAddingUsersForRate.vue';
import { RatingStatusAliases } from '@/constants/userRatingStatuses';
import NoDataStub from '@/components/common/NoDataStub.vue';
// import { SurveyStageType } from '@/constants/surveyStage';

export default Vue.extend({
  name: 'AssessmentCouplesFavorites',

  components: {
    PageTitle,
    UserForRateCardCircle,
    TTLoader,
    DialogAddingUsersForRate,
    NoDataStub,
  },

  inject: ['RouteNames'],

  data() {
    return {
      couplesLoading: false,
      couplesData: {
        groups: [] as V1EntitiesCouplesPublicExpertIndexGroup[],
        couples: [] as V1EntitiesCouplesPublicExpertIndexCouple[],
      },
      rejectingLoading: false,
      showAddingDialog: false,
    };
  },

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    ...mapGetters('survey', {
      currentDisplayedSurveyStage: 'currentDisplayedSurveyStage',
    }),
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    firstCoupleAssessmentPath(): Location {
      return {
        name: this.RouteNames.R_APP_SURVEYEE_ASSESSMENT,
        params: {
          surveyId: String(this.surveyId),
          coupleId: String(this.couplesData.couples[0]?.id),
        },
      };
    },
    filteredCouples(): V1EntitiesCouplesPublicExpertIndexCouple[] {
      return this.couplesData.couples.filter((couple) => couple.status !== RatingStatusAliases.DONE);
    },
    isNotCurrentStage(): boolean {
      if (!this.currentDisplayedSurveyStage) {
        return false;
      }

      const todayIsBetweenDates = this.$dayjs().isBetween(
        this.$dayjs(this.currentDisplayedSurveyStage.dateStart),
        this.$dayjs(this.currentDisplayedSurveyStage.dateEnd),
        // @ts-ignore
        '[]',
      );
      return this.surveyMeta.status !== 'finished' && !todayIsBetweenDates;
    },
  },

  watch: {
    showAddingDialog: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.loadCouples();
        }
      },
    },
  },

  methods: {
    async loadCouples() {
      try {
        this.couplesLoading = true;

        const {
          data: { groups, couples },
        } = await tsxassApi.getV1CouplesIamExpert(this.surveyId);
        this.couplesData = { groups, couples };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.couplesLoading = false;
      }
    },
    async onCoupleReject(coupleId: number, comment: string) {
      try {
        this.rejectingLoading = true;
        await tsxassApi.postV1CouplesCoupleIdRejected(coupleId, { comment });
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
  },
});
