
import Vue, { PropType } from 'vue';
import { V1EntitiesCouplesPublicExpertIndexCouple, V1EntitiesCouplesPublicExpertIndexCoupleAudience } from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';
import DialogRejectedComment from './DialogRejectedComment.vue';

export default Vue.extend({
  name: 'UserForRateCardCircle',

  components: {
    UserAvatar,
    DialogRejectedComment,
  },

  props: {
    coupleData: {
      type: Object as PropType<V1EntitiesCouplesPublicExpertIndexCouple>,
      required: true,
    },
  },

  data() {
    return {
      isDialogRejectedCommentShow: false,
    };
  },

  computed: {
    user(): V1EntitiesCouplesPublicExpertIndexCoupleAudience {
      return this.coupleData.surveyee;
    },
    fullName(): string {
      return `${this.user.lastName} ${this.user.firstName}`;
    },
  },

  methods: {
    onRejectClick() {
      this.isDialogRejectedCommentShow = true;
    },
  },
});
